import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import HeartIcon from "../assets/images/heart-icon.svg";
import HeartFillIcon from "../assets/images/heart-fill-icon.svg";
import ShareIcon from "../assets/images/share-icon.svg";
import CartIcon from "../assets/images/cart-icon.svg";

import whiteArrow from "../assets/images/view-all-arrow.svg";

import { fetchBestSellerProducts } from "../features/product/productActions";
import { useDispatch, useSelector } from "react-redux";
import { formatPrice } from "../helpers/helpers";
import { Navigation } from "swiper/modules";
import { Dropdown } from "react-bootstrap";
import SwiperCore from "swiper";
import SocialShareDropdown from "../component/SocialShareDropdown";
import { clearMessages as clearWishlistMessages, addRemoveWishlist } from '../features/wishlist/wishlistActions';
import { toast } from "react-toastify";

SwiperCore.use([Navigation]);

function BestSeller() {
  //const swiperRef = useRef(null);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const dispatch = useDispatch();
  const { bestSellerProducts } = useSelector((state) => state.product);
  const [bestProducts, setBestProducts] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  
  const [isHovered, setIsHovered] = useState(false);
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');
  const { successMessage: wishlistSuccessMessage } = useSelector((state) => state.wishlist);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    let data = { limit: "10" };
    dispatch(fetchBestSellerProducts(data));
  }, [dispatch]);

  useEffect(() => {
    if (bestSellerProducts.length > 0) {
      let bp = bestSellerProducts[0]["products"];
      setBestProducts(bp);
    }
  }, [bestSellerProducts]);

  function setBestProductsByIndex(index) {
    let bp = bestSellerProducts[index]["products"];
    setBestProducts(bp);
    setSelectedIndex(index);
  }

  
  const toggleAddToWishlist = (product) => {
    if (!token) {  // Check if token is missing (user is unauthorized)
      toast.error("You are not authorized to add products to the wishlist.", { autoClose: 3000, hideProgressBar: true });
      return false;
    }
    const data = {
      product_id: product.id,
    };
    // Create a copy of the product and toggle the is_wishlist property
    const updatedProduct = { ...product, is_wishlist: !product.is_wishlist };
    setBestProducts((prevProducts) => prevProducts.map((p) => p.id === updatedProduct.id ? updatedProduct : p));
    dispatch(addRemoveWishlist(data));
  };

  

  useEffect(() => {
    if (wishlistSuccessMessage) {
      dispatch(clearWishlistMessages());
    }
  }, [wishlistSuccessMessage, dispatch]);

  if (bestSellerProducts.length === 0) {
    return null;
  }

  return (
    <>
      <section className="product-slider">
        <div className="product-slider-header">
          <div className="product-slider-header-left">
            <h2>Best Seller</h2>
            <p>Find the perfect products for every chapter.</p>
          </div>
          <a href="/products" className="cutsom-bttn">
            View All Products <img src={whiteArrow} alt="" />
          </a>
        </div>
        <div className="category-list">
          <ul>
            {bestSellerProducts.map((category, cateIndex) => (
              <li key={"c" + cateIndex}>
                <Link
                  to=""
                  onClick={() => setBestProductsByIndex(cateIndex)}
                  className={`style-${(cateIndex % 4) + 1} ${
                    selectedIndex === cateIndex ? "active" : ""
                  }`}
                >
                  {category.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <Swiper
          modules={[Navigation]}
          slidesPerView={5}
          spaceBetween={30}
          pagination={{ clickable: true }}
          crollbar={{ draggable: true }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1700: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }}
        >
          {bestProducts.length > 0 && (
            <>
              {bestProducts.map((product, index) => (
                <SwiperSlide key={"product-" + index}>
                  <a
                    href={"/products/" + product.slug}
                    className={`product-slider-box ${
                      isHovered ? "hovered" : ""
                    }`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="product-slider-box-img">
                      <img
                        src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${product.image}`}
                        alt=""
                      />
                      {product.is_new && <span>New</span>}
                    </div>
                    <div className="product-slider-box-text">
                      <p>{product.category_title}</p>
                      <h3>{product.title}</h3>
                      <div className="price-box">
                        <span>Price</span>
                        <div className="price-value">
                          <p className="new-price">
                            {product.currency + formatPrice(product.sale_price)}
                          </p>
                          <del className="old-price">
                            {product.currency +
                              formatPrice(product.regular_price)}
                          </del>
                        </div>
                      </div>
                    </div>
                  </a>
                  <div
                    className={`like-share-wrap ${isHovered ? "hovered" : ""}`}
                  >
                    <span
                      className={`like-wrap ${product.is_wishlist ? "liked" : ""}`}
                      title="Share"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleAddToWishlist(product);
                      }}
                    >
                      <img className="heart-icon" src={HeartIcon} alt="" />
                      <img
                        className="heart-active-icon"
                        src={HeartFillIcon}
                        alt=""
                      />
                    </span>
                    <Dropdown className={`share-wrap`}>
                      <Dropdown.Toggle id="dropdown-basic">
                        <img src={ShareIcon} alt="" />
                      </Dropdown.Toggle>
                      <SocialShareDropdown productSlug={"/products/" + product.slug}></SocialShareDropdown>
                    </Dropdown>
                    <a
                      className={`cart-wrap`}
                      href={"/products/" + product.slug}
                      title="Add Cart"
                    >
                      <img className="cart-icon" src={CartIcon} alt="" />
                    </a>
                  </div>
                </SwiperSlide>
              ))}
            </>
          )}

          <div className="swiper-button-nav">
            <div ref={navigationPrevRef} className="swiper-button-prev"></div>
            <div ref={navigationNextRef} className="swiper-button-next"></div>
          </div>
        </Swiper>
      </section>
    </>
  );
}
export default BestSeller;
