import React, { useState, useEffect, useRef } from "react";
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Skeleton from 'react-loading-skeleton';
// import Logo from '../assets/images/logo.svg'
// import MenuBg from '../assets/images/mwnu-img.png'
import Search from '../assets/images/search.svg'
import SidebarSearchIcon from '../assets/images/sidebar-search-icon.svg'
// import SearchItemImg from '../assets/images/search-itme.png'
import Cart from '../assets/images/cart.svg'
import Like from '../assets/images/like.svg'
import User from '../assets/images/user.svg'
import whiteArrow from '../assets/images/button-arrow.svg'
import { Link, useNavigate } from 'react-router-dom'
import { Dropdown, Offcanvas } from 'react-bootstrap'

import { useDispatch, useSelector } from 'react-redux';
import { logout } from "../features/auth/authActions";
import { getPageContent } from "../features/visitor/visitorActions";
import { clearMessages, fetchBrands, fetchCategories, fetchHealthInterest, fetchProductsByKeyword } from "../features/product/productActions";
import { fetchCart } from '../features/cart/cartActions';
import { toast } from "react-toastify"; // Import toast

function Header() {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token'); //useSelector((state) => state.auth);
    const [showSidebar, setShowSidebar] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const [ourProductBoxContent, setOurProductBoxContent] = useState('');
    const [healthInterestBoxContent, setHealthInterestBoxContent] = useState('');
    const [brandBoxContent, setBrandBoxContent] = useState('');
    const [tobBarContent, setTopBarContent] = useState('');
    const [mainLogo, setMailLogo] = useState('');
    

    const isCartFetched = useRef(false); // Track whether cart has been fetched

    const handleSidebarToggle = () => setShowSidebar(!showSidebar);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { errorMessage, brands, categories, healthInterests, searchProducts } = useSelector((state) => state.product);
    const { pageResponse } = useSelector((state) => state.visitor);
    const { cart } = useSelector((state) => state.cart);

     // Handle search input change
    const handleSearchChange = (e) => {
        const keyword = e.target.value;
        setSearchTerm(keyword);

        // Dispatch action to fetch products by keyword
        if (keyword.trim() !== '') {
            let data = {
                page : 1,
                limit : 25, 
                keyword: keyword
            }
            dispatch(fetchProductsByKeyword(data));
        }
    };
    
    const handleLogout = () => {
        localStorage.removeItem('token'); 
        sessionStorage.removeItem('token');
        dispatch(logout());
        navigate('/sign-in');
    };

    const handleWishList = () => {
        navigate('/profile/wishlist');
    }

    useEffect(() => {
        let data = { page: 1, limit: 20 };
        dispatch(fetchBrands(data));
        dispatch(fetchCategories(data));
        dispatch(fetchHealthInterest(data));
        
        let dataPage = { code: 'footer' };
        dispatch(getPageContent(dataPage));

        // Only fetch the cart once
        if (!isCartFetched.current) {
            dispatch(fetchCart());
            isCartFetched.current = true; // Mark as fetched to prevent future calls
        }
        
    }, [dispatch]);
    

    const renderBrandRows = () => {
        if (!brands?.data?.length) return null;
        const rows = [];
        for (let i = 0; i < brands.data.length; i += 5) {
            rows.push(
                <div className="col-sm-4" key={i}>
                    {brands.data.slice(i, i + 5).map((row) => (
                        <Link
                            to={`/products?brands=${row.slug}`}
                            key={row.id}
                            title={row.title}
                            className="dropdown-item"
                        >
                            {row.title}
                        </Link>
                    ))}
                </div>
            );
        }
        return <>{rows}</>;
    };


    const renderCategoryRows = () => {
        if (!categories?.data?.length) return null;
        const rows = [];
        for (let i = 0; i < categories.data.length; i += 5) {
            rows.push(
                <div className="col-sm-4" key={i}>
                    {categories.data.slice(i, i + 5).map((row) => (
                        <Link
                            to={`/products?categories=${row.slug}`}
                            key={row.id}
                            title={row.title}
                            className="dropdown-item"
                        >
                            {row.title}
                        </Link>
                    ))}
                </div>
            );
        }
        return <>{rows}</>;
    };

   
    const renderHealthInterestRows = () => {
        if (!healthInterests?.data?.length) return null;
        const rows = [];
        for (let i = 0; i < healthInterests.data.length; i += 5) {
            rows.push(
                <div className="col-sm-4" key={i}>
                    {healthInterests.data.slice(i, i + 5).map((row) => (
                        <Link
                            to={`/products?healthInterests=${row.slug}`}
                            key={row.id}
                            title={row.title}
                            className="dropdown-item"
                        >
                            {row.title}
                        </Link>
                    ))}
                </div>
            );
        }
        return <>{rows}</>;
    };



    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
            dispatch(clearMessages()); // Reset the messages after showing the toast
        }
    }, [errorMessage, dispatch]);
    

    // useEffect(() => {
    //     console.log("Header token user", token)    
    // }, [token]);


    useEffect(() => {
        if(pageResponse !== null){
            let headerContent = JSON.parse(pageResponse.footer_section);
            if(headerContent){
                setBrandBoxContent(headerContent.brand_section);
                setHealthInterestBoxContent(headerContent.health_interest_advertising_section);
                setOurProductBoxContent(headerContent.order_product_advertising_section);
                setTopBarContent(headerContent.footer_header_text);
                setMailLogo(headerContent.main_logo);
                localStorage.setItem("footerSection", pageResponse.footer_section);
            }
        }   
    }, [pageResponse]);

    const [activeDropdown, setActiveDropdown] = useState(null);

  const handleMouseEnter = (id) => setActiveDropdown(id);
  const handleMouseLeave = () => setActiveDropdown(null);

  return (
   
    <>
      <header>
        {tobBarContent ? (
            <div className='header-top common-padding' dangerouslySetInnerHTML={{ __html: tobBarContent }}>   
                {/* <p><a href="/" title='Sign up'>Sign up</a> for our mailing list and get 15% off your first order. <a href="/" title='Terms apply'>Terms apply.</a></p> */}
            </div>
        ) : (
            // <div className='header-top common-padding'><p></p></div>   
            <Skeleton height="45px" width="100%" />
        )}
       
        <Navbar expand="lg" className="common-padding">
            <Navbar.Brand className='header-logo'>
                <Link to={"/"}>
                    {(mainLogo) ? (
                        <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${mainLogo}`} alt="" />
                    ) : (
                        <Skeleton width={150} height={60} />
                    )}
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="m-auto">
                    <NavDropdown className="mega-menu" title="Our Products" id="shop-all" show={activeDropdown === 1}
                onMouseEnter={() => handleMouseEnter(1)}
                onMouseLeave={handleMouseLeave}>
                        <div className="mega-menu-wrap">
                            <div className='mega-menu-cols'>
                                <div className='mega-menu-col col-two'>
                                    <h3>Categories</h3>
                                    <div className="row">
                                        {renderCategoryRows()}
                                    </div>
                                </div>
                                {(ourProductBoxContent) && (
                                <div className='mega-menu-col col-two menu-advertisement'>
                                    <img className='w-100' src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${ourProductBoxContent.image}`} alt="" />
                                    <div className="advertisement-details">
                                        <span>{ourProductBoxContent.title}</span>
                                        <h2>{ourProductBoxContent.short_title}</h2>
                                        <p>{ourProductBoxContent.description}</p>
                                        <a href={ourProductBoxContent.btn_url} className='cutsom-bttn'>{ourProductBoxContent.btn_title} <img src={whiteArrow} alt="" /></a>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                    </NavDropdown>
                    <NavDropdown className="mega-menu" title="Health Interests" id="health-interests" show={activeDropdown === 2}
                onMouseEnter={() => handleMouseEnter(2)}
                onMouseLeave={handleMouseLeave}>
                        <div className="mega-menu-wrap">
                            <div className='mega-menu-cols'>
                                <div className='mega-menu-col col-three'>
                                    <h3>Health Interests</h3>
                                    <div className="row">
                                        {renderHealthInterestRows()}
                                    </div>
                                </div>
                                {(healthInterestBoxContent) && (
                                <div className='mega-menu-col col-two menu-advertisement'>
                                    <img className='w-100' src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${healthInterestBoxContent.image}`} alt="" />
                                    <div className="advertisement-details">
                                        <span>{healthInterestBoxContent.title}</span>
                                        <h2>{healthInterestBoxContent.short_title}</h2>
                                        <p>{healthInterestBoxContent.description}</p>
                                        <a href={healthInterestBoxContent.btn_url} className='cutsom-bttn'>{healthInterestBoxContent.btn_title} <img src={whiteArrow} alt="" /></a>
                                    </div>
                                </div>
                                )}

                            </div>
                        </div>
                    </NavDropdown>
                    <NavDropdown className="mega-menu" title="Brands" id="brands" show={activeDropdown === 3}
                onMouseEnter={() => handleMouseEnter(3)}
                onMouseLeave={handleMouseLeave}>
                        <div className="mega-menu-wrap">
                            <div className='mega-menu-cols'>
                                
                                <div className='mega-menu-col col-three'>
                                    <h3>Our Brands</h3>
                                    <div className="row">
                                        {renderBrandRows()}
                                    </div>
                                    {/* <Row dangerouslySetInnerHTML={{__html: renderBrandRows()}}></Row> */}
                                </div>

                                {(brandBoxContent) && (
                                <div className='mega-menu-col col-two menu-advertisement'>
                                    <img className='w-100' src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${brandBoxContent.image}`} alt="" />
                                    <div className="advertisement-details">
                                        <span>{brandBoxContent.title}</span>
                                        <h2>{brandBoxContent.short_title}</h2>
                                        <p>{brandBoxContent.description}</p>
                                        <a href={brandBoxContent.btn_url} className='cutsom-bttn'>{brandBoxContent.btn_title} <img src={whiteArrow} alt="" /></a>
                                    </div>
                                </div>
                                )}

                            </div>
                        </div>
                    </NavDropdown>
                   
                    <Nav.Link title="Blogs"  as={Link} to="/blogs">Blogs</Nav.Link> 
                    <Nav.Link title="About"  as={Link} to="/about-us">About</Nav.Link>  
                    <Nav.Link title="Contact Us"  as={Link} to="/contact-us">Contact Us</Nav.Link>                    
                </Nav>
                <Nav className='header-right'>
                    <Nav.Link className='header-icon' href="#" onClick={handleSidebarToggle}><img src={Search} alt="" /></Nav.Link>                     
                    <Nav.Link className='header-icon header-cart-link' as={Link} to="/cart">
                        <img src={Cart} alt="cart" />
                        {cart?.length > 0 && (
                            <span className='cart-count'>{cart.length}</span>
                        )}
                    </Nav.Link>  
                    {(token === null) ? (
                    <Nav.Link className='header-icon' as={Link} to="/sign-in" ><img src={User} alt="sign-in" /></Nav.Link> 
                    ) : (
                    <>
                    <Nav.Link className='header-icon' onClick={handleWishList}><img src={Like} alt="wish-list" /></Nav.Link> 
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                            <img className='w-100' src={User} alt="" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu  align={{ lg: 'end' }}>
                            <Dropdown.Item as={Link} to="/profile">Profile</Dropdown.Item>
                            {/* <Dropdown.Item href="#">Setting</Dropdown.Item> */}
                            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>  
                    </>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
      </header>
      <Offcanvas show={showSidebar} onHide={handleSidebarToggle} placement="end" className="custom-offcanvas">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Search Products</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className='search-box'>
            <form method="get">
                <label className="searchbutton" for="searchright"><img src={SidebarSearchIcon} alt="" /></label>
                <input 
                className="search-input"
                id="searchright"
                type="search"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange} 
                />
            </form>
            <ul>
                {/* Loop through searchProducts and display them */}
                {searchProducts && searchProducts.length > 0 ? (
                    searchProducts.map((product) => (
                    <li key={product.id}>
                        <a href={`/products/${product.slug}`} className='search-item'>
                        <div className="search-item-img">
                            <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${product.image}`} alt={product.title} />
                        </div>
                        <div className="search-item-text">
                            <h3>{product.title}</h3>
                            <p>{product.short_description || 'No description available.'}</p>
                        </div>
                        </a>
                    </li>
                    ))
                ) : (
                    <li>No products found</li>
                )}
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
export default Header


