import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";


function PowerNature({ powerNatureSection }) {
  
  const [allSliders, setAllSliders] = useState([]);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const { sliderResponse } = useSelector((state) => state.visitor);

  useEffect(() => {
    // Handle cases where dynamic data is not immediately available
    if (!powerNatureSection) return;
  }, [powerNatureSection]);

  useEffect(() => {
    if (sliderResponse?.data) {
      const filteredSliders = sliderResponse.data.filter(slider => slider.type === 2);
      setAllSliders(filteredSliders);
    }
  }, [sliderResponse]);

  

  if (!powerNatureSection) {
    // Show a loading state or fallback UI if powerNatureSection is not yet loaded
    return <div></div>;
  }

  return (
    <section className="power-nature">
      <div className="power-nature-wrap">
        <div className="common-header">
          <span>{powerNatureSection?.title || "Inspired by the"}</span>
          <h2>{powerNatureSection?.short_title || "Power of Nature"}</h2>
          <p>
            {powerNatureSection?.description ||
              "Default description if missing."}
          </p>
        </div>

        <Swiper
          modules={[Navigation]}
          slidesPerView={3}
          spaceBetween={30}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
        >
          {allSliders.map((slide, index) => (
          <SwiperSlide key={slide.id}>
            <Link to="">
              <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${slide.image}`} alt="" />
            </Link>
          </SwiperSlide>
          ))}

          
          <div className="swiper-button-nav">
            <div ref={navigationPrevRef} className="swiper-button-prev"></div>
            <div ref={navigationNextRef} className="swiper-button-next"></div>
          </div>
        </Swiper>

      </div>
    </section>
  );
}

export default PowerNature;
