import React from "react";
import { Dropdown } from "react-bootstrap";

const SocialShareDropdown = ({ productSlug }) => {
  const webUrl = `${window.location.origin}${productSlug}`;

  const socialLinks = [
    {
      name: "Facebook",
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        webUrl
      )}`,
    },
    {
      name: "Twitter",
      url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(webUrl)}`,
    },
    {
      name: "LinkedIn",
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        webUrl
      )}`,
    },
    {
      name: "Pinterest",
      url: `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
        webUrl
      )}`,
    },
    {
      name: "Whatsapp",
      url: `https://api.whatsapp.com/send?text=${encodeURIComponent(webUrl)}`,
    },
  ];

  return (
    <Dropdown.Menu align={{ lg: "end" }}>
      {socialLinks.map((link, index) => (
        <Dropdown.Item
          key={index}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {link.name}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  );
};

export default SocialShareDropdown;
