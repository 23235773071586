import React, { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

function HomeTodayOffer({ offerSection }) {
  // const swiperRef = useRef(null);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  // State to store all fetched brands
  const [homeBrands, setHomeBrands] = useState([]);

  const { brands } = useSelector((state) => state.product);

  useEffect(() => {
    if (brands?.data?.length > 0) {
      setHomeBrands(brands.data);
    }
  }, [brands]);

  
  return (
    <>
      <section className="home-today-offer">
        <div className="common-header">
          <h2>{offerSection?.title}</h2>
          <p>{offerSection?.description}</p>
        </div>
        <Swiper
          modules={[Navigation]}
          slidesPerView={4}
          spaceBetween={30}
          pagination={{ clickable: true }}
          crollbar={{ draggable: true }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1700: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          }}
        >
          {!offerSection ? ( // Check if loading is true
            <>
              {Array.from({ length: 4 }).map(
                (
                  _,
                  index // Adjust the length based on how many skeletons you want
                ) => (
                  <SwiperSlide key={index}>
                    {" "}
                    {/* Add a unique key */}
                    <div className="today-offer-box">
                      <Skeleton height={150} /> {/* Skeleton for image */}
                      <Skeleton
                        height={20}
                        width="80%"
                        style={{ marginTop: "10px" }}
                      />{" "}
                      {/* Skeleton for title */}
                      <Skeleton
                        height={16}
                        width="60%"
                        style={{ marginTop: "5px" }}
                      />{" "}
                      {/* Skeleton for description */}
                    </div>
                  </SwiperSlide>
                )
              )}
            </>
          ) : (
            offerSection?.data?.length > 0 && (
              <>
                {offerSection.data.map((row, index) => (
                  <SwiperSlide key={index}>
                    {" "}
                    {/* Add a unique key */}
                    <Link className="today-offer-box" to={row.link}>
                      <img
                        src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${row.image}`}
                        alt=""
                      />
                      <h3>
                        {row.title}
                        <br />
                        {row.description}
                      </h3>
                    </Link>
                  </SwiperSlide>
                ))}
              </>
            )
          )}

          <div className="swiper-button-nav">
            <div ref={navigationPrevRef} className="swiper-button-prev"></div>
            <div ref={navigationNextRef} className="swiper-button-next"></div>
          </div>
        </Swiper>
      </section>
      <section className="client-logo">
        <Swiper
          modules={[Navigation, Autoplay]}
          slidesPerView={6}
          loop={true}
          spaceBetween={30}
          pagination={{ clickable: true }}
          crollbar={{ draggable: true }}
          navigation={false}
          autoplay={{
            delay: 2500, // Adjust delay as needed
            disableOnInteraction: false, // Continue autoplay after interaction
          }}
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            480: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 20,
            },
            1700: {
              slidesPerView: 6,
              spaceBetween: 30,
            },
          }}
        >
          
          {(!offerSection) ? ( // Check if loading is true
            <>
              {Array.from({ length: 10 }).map((_, index) => ( // Adjust the length based on how many skeletons you want
                <SwiperSlide key={index}>
                  <div className="brand-skeleton-box">
                    <Skeleton height={100} /> {/* Skeleton for brand image */}
                  </div>
                </SwiperSlide>
              ))}
            </>
          ) : (
            homeBrands.slice(0, 10).map((brand) => (
              <SwiperSlide key={brand.id}>
                <Link to={`/products?brands=${brand.slug}`}>
                  <img
                    src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${brand.image}`}
                    alt={brand.title}
                  />
                </Link>
              </SwiperSlide>
            ))
          )}

        </Swiper>
      </section>
    </>
  );
}
export default HomeTodayOffer;
