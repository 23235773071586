import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import HeartIcon from "../assets/images/heart-icon.svg";
import HeartFillIcon from "../assets/images/heart-fill-icon.svg";
import ShareIcon from "../assets/images/share-icon.svg";
import CartIcon from "../assets/images/cart-icon.svg";
import whiteArrow from "../assets/images/view-all-arrow.svg";
import { Dropdown } from "react-bootstrap";

import { Navigation } from "swiper/modules";
import SwiperCore from "swiper";
import { formatPrice } from "../helpers/helpers";
import { useDispatch, useSelector } from "react-redux";
import SocialShareDropdown from "../component/SocialShareDropdown";
import {
  clearMessages as clearWishlistMessages,
  addRemoveWishlist,
} from "../features/wishlist/wishlistActions";
import { toast } from "react-toastify";

SwiperCore.use([Navigation]);

function MedicineHealthProducts({ categoryOfferProductSection }) {
  // const swiperRef = useRef(null);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const [isHovered, setIsHovered] = useState(false);
  const [updatedProductList, setUpdatedProductList] = useState(
    categoryOfferProductSection?.data || []
  ); // Local state for products
  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  const { successMessage: wishlistSuccessMessage } = useSelector(
    (state) => state.wishlist
  );
  const dispatch = useDispatch();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const toggleAddToWishlist = (product) => {
    if (!token) {
      // Check if token is missing (user is unauthorized)
      toast.error("You are not authorized to add products to the wishlist.", {
        autoClose: 3000,
        hideProgressBar: true,
      });
      return false;
    }
    const data = {
      product_id: product.id,
    };
    const updatedProduct = { ...product, is_wishlist: !product.is_wishlist };
    dispatch(addRemoveWishlist(data));

    // Update the local state immediately
    const updatedProducts = updatedProductList.map((p) =>
      p.id === product.id ? updatedProduct : p
    );
    setUpdatedProductList(updatedProducts);
  };

  useEffect(() => {
    if (wishlistSuccessMessage) {
      dispatch(clearWishlistMessages());
    }
  }, [wishlistSuccessMessage, dispatch]);

  useEffect(() => {
    setUpdatedProductList(categoryOfferProductSection?.data);
  }, [categoryOfferProductSection?.data]);

  if (!categoryOfferProductSection) {
    return null;
  }

  return (
    <>
      <section className="product-slider medicine-health-products">
        <div className="product-slider-header">
          <div className="product-slider-header-left">
            <h2>{categoryOfferProductSection?.title}</h2>
            <p>{categoryOfferProductSection?.description}</p>
          </div>
          <a href="/products" className="cutsom-bttn">
            View All Products <img src={whiteArrow} alt="" />
          </a>
        </div>
        <Swiper
          modules={[Navigation]}
          slidesPerView={5}
          spaceBetween={30}
          pagination={{ clickable: true }}
          crollbar={{ draggable: true }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1700: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }}
        >
          {updatedProductList?.length > 0 && (
            <>
              {updatedProductList?.map((product, index) => (
                <SwiperSlide key={"product-" + index}>
                  <a
                    href={"/products/" + product.slug}
                    className={`product-slider-box ${
                      isHovered ? "hovered" : ""
                    }`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="product-slider-box-img">
                      <img
                        src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${product.image}`}
                        alt=""
                      />
                      {product.is_new && <span>New</span>}
                    </div>
                    <div className="product-slider-box-text">
                      <p>{product.category_title}</p>
                      <h3>{product.title}</h3>
                      <div className="price-box">
                        <span>Price</span>
                        <div className="price-value">
                          <p className="new-price">
                            {product.currency + formatPrice(product.sale_price)}
                          </p>
                          <del className="old-price">
                            {product.currency +
                              formatPrice(product.regular_price)}
                          </del>
                        </div>
                      </div>
                    </div>
                  </a>
                  <div
                    className={`like-share-wrap ${isHovered ? "hovered" : ""}`}
                  >
                    <span
                      className={`like-wrap ${
                        product.is_wishlist ? "liked" : ""
                      }`}
                      title="Share"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleAddToWishlist(product);
                      }}
                    >
                      <img className="heart-icon" src={HeartIcon} alt="" />
                      <img
                        className="heart-active-icon"
                        src={HeartFillIcon}
                        alt=""
                      />
                    </span>
                    <Dropdown className={`share-wrap`}>
                      <Dropdown.Toggle id="dropdown-basic">
                        <img src={ShareIcon} alt="" />
                      </Dropdown.Toggle>
                      <SocialShareDropdown
                        productSlug={"/products/" + product.slug}
                      ></SocialShareDropdown>
                    </Dropdown>
                    <a
                      className={`cart-wrap`}
                      href={"/products/" + product.slug}
                      title="Add Cart"
                    >
                      <img className="cart-icon" src={CartIcon} alt="" />
                    </a>
                  </div>
                </SwiperSlide>
              ))}
            </>
          )}

          <div className="swiper-button-nav">
            <div ref={navigationPrevRef} className="swiper-button-prev"></div>
            <div ref={navigationNextRef} className="swiper-button-next"></div>
          </div>
        </Swiper>
      </section>
    </>
  );
}
export default MedicineHealthProducts;
